import moment from 'moment';
import { getFawkesMainFromSparkyStore } from 'src/appUtils';
import { DATE_MARKER, FORCED_DATE_FORMAT, FORCED_TYPE } from 'src/containers/main/notifications/const';
const defaultFormat = 'YYYY-MMM-DD hh:mm A';

export const getCurrentTime = () => moment().local();

export const convertToLocal = (timestamp) => moment.unix(timestamp).local();

export const toLocalFormat = (timestamp, format = defaultFormat) => convertToLocal(timestamp).format(format);

export const isCurrentTimeSlot = ({ currentTime, startTime, endTime }) => {
	const localStartTime = convertToLocal(startTime);
	const localEndTime = convertToLocal(endTime);

	return localStartTime.isSameOrBefore(currentTime) && localEndTime.isSameOrAfter(currentTime);
};

const decodeData = (str) => {
	try {
		const withoutSpace = str.replace(/\s/g, '');
		const decodedStr = atob(withoutSpace);

		return decodeURIComponent(escape(decodedStr));
	} catch (e) {
		return str;
	}
};

export const decodeText = (message) => {
	const decodedText = decodeData(message);

	return decodedText;
};

export const generateQueryParams = (params) =>
	Object.keys(params)
		.map((key) => key + '=' + params[key])
		.join('&');
export const filterRelevant = (notifications, currentTime) => {
	return notifications.filter(({ startTime, endTime }) =>
		isCurrentTimeSlot({
			currentTime,
			startTime,
			endTime,
		}),
	);
};

export const onlyPAForced = ({ type, app }) => type === FORCED_TYPE && app === 'prisma_access';

export const isForced = (notifications) => {
	return filterRelevant(notifications, getCurrentTime()).filter(onlyPAForced);
};

export const transformNotification = (notification) => {
	const time = toLocalFormat(notification.endTime, FORCED_DATE_FORMAT);
	const message = decodeText(notification.message).replace(DATE_MARKER, time);

	return {
		...notification,
		message,
	};
};

export const isAppOnMaintenance = () => {
	const fawkesMain = getFawkesMainFromSparkyStore();
	//to decouple
	const notifications = _.get(fawkesMain, 'notificationsInfo', []);
	const forcedNotifications = isForced(_.isArray(notifications) ? notifications : []);
	// return true;
	return !!forcedNotifications.length;
};
