/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/*!
 * PAN Management UI
 * Copyright(c) Palo Alto Networks, Inc.
 */
import { FAWKES } from 'src/typings/type';
let idSeed = 0;
export default class Pan {
	static decode(arg0: any): any {
		throw new Error('Method not implemented.');
	}

	static get emptyFn() {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		return function (): void {};
	}

	/**
	 * Copies all the properties of config to obj.
	 * @param {Object} obj The receiver of the properties
	 * @param {Object} config The source of the properties
	 * @param {Object} defaults A different object that will also be applied for default values
	 * @return {Object} returns obj
	 * @member Pan apply
	 */
	static apply(o: FAWKES.I_OBJECT, c: FAWKES.I_OBJECT, defaults?: FAWKES.I_OBJECT | undefined): FAWKES.I_OBJECT {
		// no "this" reference for friendly out of scope calls
		if (defaults) {
			Pan.apply(o, defaults, undefined);
		}
		if (o && c && typeof c == 'object') {
			for (const p in c) {
				o[p] = c[p];
			}
		}
		return o;
	}

	/**
	 * Copies all the properties of config to obj if they don't already exist.
	 * @param {Object} obj The receiver of the properties
	 * @param {Object} config The source of the properties
	 * @return {Object} returns obj
	 */
	static applyIf(o: FAWKES.I_OBJECT, c: FAWKES.I_OBJECT): FAWKES.I_OBJECT {
		if (o) {
			for (const p in c) {
				if (!Pan.isDefined(o[p])) {
					o[p] = c[p];
				}
			}
		}
		return o;
	}

	static clone(obj: FAWKES.I_OBJECT): FAWKES.I_OBJECT {
		const dup = function (obj: FAWKES.I_OBJECT) {
			let rv = obj;
			if (rv !== undefined && rv !== null) {
				rv = obj.__clone__;
				if (!Pan.isDefined(rv)) {
					if (Pan.isArray(obj)) {
						obj.__clone__ = rv = obj.slice(0);
						for (let i = 0; i < rv.length; i++) {
							rv[i] = dup(rv[i]);
						}
					} else if (Pan.isObject(obj) && obj.__cloneable__ !== false) {
						rv = {};
						for (const m in obj) {
							if (Object.prototype.hasOwnProperty.call(obj, m)) {
								rv[m] = dup(obj[m]);
							}
						}
						obj.__clone__ = rv;
					} else {
						rv = obj;
					}
				}
			}
			return rv;
		};
		const rv = dup(obj);
		const cleanup = function (obj: FAWKES.I_OBJECT) {
			if (obj !== undefined && obj !== null) {
				if (Pan.isDefined(obj.__clone__)) {
					delete obj.__clone__;
				}
				if (Pan.isArray(obj)) {
					for (let i = 0; i < obj.length; i++) {
						cleanup(obj[i]);
					}
				} else if (Pan.isObject(obj) && obj.__cloneable__ !== false) {
					for (const m in obj) {
						if (Object.prototype.hasOwnProperty.call(obj, m)) {
							cleanup(obj[m]);
						}
					}
				}
			}
		};
		cleanup(obj);
		return rv;
	}

	/**
	 * Generates unique ids. If the element already has an id, it is unchanged
	 * @param {Mixed} el (optional) The element to generate an id for
	 * @param {String} prefix (optional) Id prefix (defaults "pan-gen-")
	 * @return {String} The generated Id.
	 */
	static id(prefix?: string | undefined): string {
		const id = (prefix || 'pan-gen-') + ++idSeed;
		return id;
	}

	static isIterable(v: any): boolean {
		//check for array or arguments
		if (Pan.isArray(v) || v.callee) {
			return true;
		}
		//check for node list type
		if (/NodeList|HTMLCollection/.test(toString.call(v))) {
			return true;
		}
		//NodeList has an item and length property
		//XMLDOMNodeList has nextNode method, needs to be checked first.
		return (typeof v.nextNode != 'undefined' || v.item) && Pan.isNumber(v.length);
	}

	/**
	 * Iterates an array calling the supplied function.
	 * @param {Array/NodeList/Mixed} array The array to be iterated. If this
	 * argument is not really an array, the supplied function is called once.
	 * @param {Function} fn The function to be called with each item. If the
	 * supplied function returns false, iteration stops and this method returns
	 * the current <code>index</code>. This function is called with
	 * the following arguments:
	 * <div class="detail-params"><ul>
	 * <li><code>item</code> : <i>Mixed</i>
	 * <div class="sub-desc">The item at the current <code>index</code>
	 * in the passed <code>array</code></div></li>
	 * <li><code>index</code> : <i>Number</i>
	 * <div class="sub-desc">The current index within the array</div></li>
	 * <li><code>allItems</code> : <i>Array</i>
	 * <div class="sub-desc">The <code>array</code> passed as the first
	 * argument to <code>Pan.each</code>.</div></li>
	 * </ul></div>
	 * @param {Object} scope The scope (<code>this</code> reference) in which the specified function is executed.
	 * Defaults to the <code>item</code> at the current <code>index</code>
	 * within the passed <code>array</code>.
	 * @return See description for the fn parameter.
	 */
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	static each(array: any, fn: any, scope?: FAWKES.I_OBJECT | undefined): void | number {
		if (Pan.isEmpty(array, true)) {
			return;
		}
		if (!Pan.isIterable(array) || Pan.isPrimitive(array)) {
			array = [array];
		}
		for (let i = 0, len = array.length; i < len; i++) {
			if (fn.call(scope || array[i], array[i], i, array) === false) {
				return i;
			}
		}
	}

	/**
	 * Iterates either the elements in an array, or each of the properties in an object.
	 * <b>Note</b>: If you are only iterating arrays, it is better to call {@link #each}.
	 * @param {Object/Array} object The object or array to be iterated
	 * @param {Function} fn The function to be called for each iteration.
	 * The iteration will stop if the supplied function returns false, or
	 * all array elements / object properties have been covered. The signature
	 * varies depending on the type of object being interacted:
	 * <div class="detail-params"><ul>
	 * <li>Arrays : <tt>(Object item, Number index, Array allItems)</tt>
	 * <div class="sub-desc">
	 * When iterating an array, the supplied function is called with each item.</div></li>
	 * <li>Objects : <tt>(String key, Object value, Object)</tt>
	 * <div class="sub-desc">
	 * When iterating an object, the supplied function is called with each key-value pair in
	 * the object, and the iterated object</div></li>
	 * </ul></div>
	 * @param {Object} scope The scope (<code>this</code> reference) in which the specified function is executed. Defaults to
	 * the <code>object</code> being iterated.
	 */
	static iterate(
		obj: FAWKES.I_OBJECT,
		fn: { (key: any, item: any): void; call?: any },
		scope: FAWKES.I_OBJECT | undefined,
	): void {
		if (Pan.isEmpty(obj)) {
			return;
		}
		if (Pan.isIterable(obj)) {
			Pan.each(obj, fn, scope);
			return;
		} else if (Pan.isObject(obj)) {
			for (const prop in obj) {
				if (Object.prototype.hasOwnProperty.call(obj, prop)) {
					if (fn.call(scope || obj, prop, obj[prop], obj) === false) {
						return;
					}
				}
			}
		}
	}

	/**
	 * <p>Returns true if the passed value is empty.</p>
	 * <p>The value is deemed to be empty if it is<div class="detail-params"><ul>
	 * <li>null</li>
	 * <li>undefined</li>
	 * <li>an empty array</li>
	 * <li>a zero length string (Unless the <tt>allowBlank</tt> parameter is <tt>true</tt>)</li>
	 * </ul></div>
	 * @param {Mixed} value The value to test
	 * @param {Boolean} allowBlank (optional) true to allow empty strings (defaults to false)
	 * @return {Boolean}
	 */
	static isEmpty(v: any, allowBlank?: boolean): boolean {
		return (
			v === null ||
			v === undefined ||
			(Pan.isArray(v) && !v.length) ||
			(Pan.isObject(v) && Object.keys(v).length === 0) ||
			(!allowBlank ? v === '' : false)
		);
	}

	/**
	 * Returns true if the passed value is a JavaScript array, otherwise false.
	 * @param {Mixed} value The value to test
	 * @return {Boolean}
	 */
	static isArray(v: unknown): boolean {
		return toString.apply(v) === '[object Array]';
	}

	/**
	 * Returns true if the passed object is a JavaScript date object, otherwise false.
	 * @param {Object} object The object to test
	 * @return {Boolean}
	 */
	static isDate(v: FAWKES.I_OBJECT): boolean {
		return toString.apply(v) === '[object Date]';
	}

	/**
	 * Returns true if the passed value is a JavaScript Object, otherwise false.
	 * @param {Mixed} value The value to test
	 * @return {Boolean}
	 */
	static isObject(v: unknown): boolean {
		return !!v && Object.prototype.toString.call(v) === '[object Object]';
	}

	/**
	 * Returns true if the passed value is a JavaScript 'primitive', a string, number or boolean.
	 * @param {Mixed} value The value to test
	 * @return {Boolean}
	 */
	static isPrimitive(v: unknown): boolean {
		return Pan.isString(v) || Pan.isNumber(v) || Pan.isBoolean(v);
	}

	/**
	 * Returns true if the passed value is a JavaScript Function, otherwise false.
	 * @param {Mixed} value The value to test
	 * @return {Boolean}
	 */
	static isFunction(v: unknown): boolean {
		return toString.apply(v) === '[object Function]';
	}

	/**
	 * Returns true if the passed value is a number. Returns false for non-finite numbers.
	 * @param {Mixed} value The value to test
	 * @return {Boolean}
	 */
	static isNumber(v: unknown): boolean {
		return typeof v === 'number' && isFinite(v);
	}

	/**
	 * Returns true if the passed value is a string.
	 * @param {Mixed} value The value to test
	 * @return {Boolean}
	 */
	static isString(v: unknown): boolean {
		return typeof v === 'string';
	}

	/**
	 * Returns true if the passed value is a boolean.
	 * @param {Mixed} value The value to test
	 * @return {Boolean}
	 */
	static isBoolean(v: unknown): boolean {
		return typeof v === 'boolean';
	}

	/**
	 * Returns true if the passed value is not undefined.
	 * @param {Mixed} value The value to test
	 * @return {Boolean}
	 */
	static isDefined(v: unknown): boolean {
		return typeof v !== 'undefined';
	}

	/**
	 * Returns object encoded for HTML
	 * @param {object} value The value to encode
	 * @return {String}
	 */
	static htmlEncode(object: any): string | FAWKES.I_OBJECT {
		if (Pan.isArray(object)) {
			Pan.each(object, function (value: FAWKES.I_OBJECT, index: string | number, array: { [x: string]: any }) {
				array[index] = Pan.htmlEncode(value);
			});
			return object;
		} else if (Pan.isObject(object)) {
			for (const property in object) {
				if (Object.prototype.hasOwnProperty.call(object, property)) {
					const value = object[property];
					object[property] = Pan.htmlEncode(value);
				}
			}
			return object;
		} else if (Pan.isString(object)) {
			return object.replace(/&/g, '&amp;').replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/"/g, '&quot;');
		} else if (object === null || object === undefined) {
			return object;
		} else {
			return object;
		}
	}
}
