export const UNREAD_STATUS = 'unread';
export const ALL_NOTIFICATIONS = 'all';
export const FORCED_TYPE = 'FORCED';
export const NON_FORCED_TYPE = 'NON_FORCED';
export const ANNOUNCEMENT_TYPE = 'ANNOUNCEMENT';
export const NOTIFICATION_URL = 'api/v1/notifications';
export const POST_READ_STATUS_URL = `${NOTIFICATION_URL}/read-status`;

export const DATE_MARKER = '[date]';
export const FORCED_DATE_FORMAT = 'hh:mm A / YYYY-MMM-DD';
