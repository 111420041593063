import { Store } from 'redux';

let store: Store;

export function getStore(): Store {
	return store;
}

export function registerStore(str: Store): void {
	store = str;
}

export function getStoreState(): any {
	return store ? store.getState() : {};
}
