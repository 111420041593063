import { store as sparkyStore, hasTsgSupport } from 'sparky-framework';
import { isUserRoleHasManagePermission, isUserRoleAdemSupport, PANORAMA_CONFIG, FAWKES_CONFIG } from 'src/appUtils';
import StartupManager from 'src/containers/main/StartupManager';
import { isAppOnMaintenance } from 'src/containers/main/notifications/utils';
import { MSP_ENABLED } from 'src/version';

export const isNotificationCallComplete = () => {
	const res =
		sparkyStore.getState()?.fawkesMain?.notificationsInfo ||
		sparkyStore.getState()?.fawkesMain?.notifications?.callComplete;

	return res;
};

export const saseAvail = (param) => {
	if (hasTsgSupport()) {
		if (MSP_ENABLED) {
			return (
				Boolean(param?.instances?.isFawkesRunning) || Boolean(param?.instances?.isRunningStatus('bulk_config'))
			);
		}
		return Boolean(param?.instances?.isFawkesRunning);
	}
	return true;
};

export const isManageEnabled = function (configLocation) {
	return (
		saseAvail(configLocation) &&
		isNotificationCallComplete() &&
		!isAppOnMaintenance() &&
		isUserRoleHasManagePermission(this) &&
		(StartupManager.getConfigManagedType() === FAWKES_CONFIG ||
			(StartupManager.getConfigManagedType() === PANORAMA_CONFIG &&
				StartupManager.isMigrationStateEligibleForFawkesUI() &&
				!StartupManager.hideManagementUIBasedOnMigrationState()))
	);
};

export const isWorkflowsEnabled = function (configLocation) {
	return (
		saseAvail(configLocation) ||
		(StartupManager.getConfigManagedType() === PANORAMA_CONFIG &&
			isNotificationCallComplete() &&
			!isAppOnMaintenance() &&
			isUserRoleHasManagePermission(this) &&
			(StartupManager.getConfigManagedType() === FAWKES_CONFIG ||
				(StartupManager.isMigrationStateEligibleForFawkesUI() &&
					!StartupManager.hideManagementUIBasedOnMigrationState())))
	);
};

export const isMaintenanceMode = function (configLocation) {
	return (
		saseAvail(configLocation) &&
		isNotificationCallComplete() &&
		isAppOnMaintenance() &&
		isUserRoleHasManagePermission(this) &&
		(StartupManager.getConfigManagedType() === FAWKES_CONFIG ||
			(StartupManager.getConfigManagedType() === PANORAMA_CONFIG &&
				StartupManager.isMigrationStateEligibleForFawkesUI() &&
				!StartupManager.hideManagementUIBasedOnMigrationState()))
	);
};

export const isPanoramaManaged = function (configLocation) {
	return (
		saseAvail(configLocation) &&
		!isUserRoleAdemSupport(this) &&
		isNotificationCallComplete() &&
		!isAppOnMaintenance() &&
		StartupManager.getConfigManagedType() === PANORAMA_CONFIG &&
		// StartupManager.isMigrationStateEligibleForFawkesUI() &&
		!StartupManager.hideManagementUIBasedOnMigrationState()
	);
};
