
const navToRbacPermissionMapping = {
    "home": ["vnr.command_center"],
    "ac-home": ["vnr.ai_access"]
}

type NavKey = keyof typeof navToRbacPermissionMapping;

enum AccessTypes {
  WRITE = "write",
  READ = "read",
  NO_ACCESS = "no-access"
}

const getRbacAccessType = (key: NavKey, authData: any): AccessTypes => {
    const permissionSets = navToRbacPermissionMapping[key];

    if (!permissionSets || permissionSets.length === 0) {
        return AccessTypes.NO_ACCESS; //no entry for the nav key, so assume it's not visible
    }

    const accessTypes = permissionSets.map(pSet => {
        return authData?.access?.checkPermissionSet(pSet);
    })

    if (accessTypes.includes("write")) {
        return AccessTypes.WRITE;
    }
    if (accessTypes.includes("read")) {
        return AccessTypes.READ;
    }
    return AccessTypes.NO_ACCESS;
}

export const hasRBACAccess = (key: NavKey, authData: any): boolean => {
    const accessType = getRbacAccessType(key, authData);
    return accessType !== AccessTypes.NO_ACCESS;
}