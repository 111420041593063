export const baseConfig = {
	show_push_config: true,
};

export const mspConfig = {
	...baseConfig,
	show_push_config: false,
};
export const APP_BASE_URL_PREFIX = '/manage';
export const MSP_APP_BASE_URL_PREFIX = APP_BASE_URL_PREFIX.replace(APP_BASE_URL_PREFIX, '/msp/manage/snippets');
