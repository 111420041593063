import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/* Warning: please do not statically import any dependencies here except for static assets like icons */
import { ChartBarIcon } from "@panwds/icons";
import { lazy, Suspense } from "react";
import { history, getFeatureFlag } from "@sparky/framework";
/**
 *
 * SASE IA Micro App Covers the following use cases
 * Use Case 1: Provide a stitched view for tab level integration with routes, TabListContainer
 * Use Case 2: Provide a Tenant vs Sub Tenant Selector for GCS vs Customer App
 * Use Case 3: Provide tenancy information in case of stitched or PAI exposed components
 *
 */
const loadApp = async () => {
    const CanvasAI = lazy(async () => import("./canvas-ai"));
    return {
        default: () => (_jsx(Suspense, { fallback: _jsx(_Fragment, { children: "loading..." }), children: _jsx(CanvasAI, { href: location.href, dependencies: { history }, routePrefix: "/ai-canvas" }) })),
    };
};
export default function init() {
    return async function tsgLoad() {
        return {
            navigation: [
                {
                    key: "ai-canvas",
                    title: "AI Canvas",
                    expandable: true,
                    path: "/ai-canvas",
                    noRBACheck: true,
                    exact: false,
                    icon: ChartBarIcon, // either svg data url or panwds icon component, only needed in the first level
                    mergeNav: true, // required if the first level is shared
                    children: [
                        // {
                        //   key: "data-explorer",
                        //   title: "Data Explorer",
                        //   path: "/ai-canvas/data-explorer",
                        //   noRBACheck: true,
                        // avail: () => getFeatureFlag("netsec.dataspace.aicanvas"),
                        // },
                        {
                            key: "ai-canvas",
                            title: "AI Canvas",
                            path: "/ai-canvas/canvas",
                            noRBACheck: true,
                            // avail: () => getFeatureFlag("netsec.dataspace.aicanvas"),
                        },
                        {
                            key: "widget-library",
                            title: "Widget Library",
                            path: "/ai-canvas/widgets",
                            noRBACheck: true,
                            // avail: () => getFeatureFlag("netsec.dataspace.aicanvas"),
                        },
                    ],
                    // in this example, the micro-app owns the first level, if the first level is shared, please have below in the 2nd level or whatever level it owns
                    contentClassName: "canvas-ai-root panwds-tw3", // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
                    component: () => loadApp(),
                    avail: () => getFeatureFlag("sparky.framework.netsec_dataspace_aicanvas"),
                },
            ],
            /*exposes: {
                      default: {
                          TenantFetcher: () => import('./TenantFetcherWrapper'),
                          TenantSelector: () => import('./TenantSelectorWrapper'),
                          SubTenantSelector: () => import('./SubTenantSelectorWrapper'),
                          GlobalSearch: () => import('./GlobalSearchWrapper')
                      },
                  },*/
        };
    };
}
