/* eslint-disable */
/* eslint-disable strict */
/* eslint-disable no-eval */
/* eslint-disable no-useless-escape */
/* JSONPath - XPath for JSON
 */

declare global {
	interface Window {
		jsonPath: any;
	}
}

import jsonQ from 'jsonq';
import Pan from 'src/ui-lib/core/autorender/schema/Pan';
import _ from 'lodash';
import { INJECTED_REC_ID, CURRENT_ITEM_KEY } from 'src/appUtils';
import { FAWKES } from 'src/typings/type';

let jp = require('jsonpath');

export function jsonPath(obj: FAWKES.I_OBJECT, expr: string, arg?: { resultType: any } | undefined) {
	var P: {
		[k: string]: any;
	} = {
		resultType: (arg && arg.resultType) || 'VALUE',
		result: [],
		normalize: function (expr: string) {
			var subx: any = [];
			return expr
				.replace(/[\['](\??\(.*?\))[\]']/g, function ($0, $1) {
					return '[#' + (subx.push($1) - 1) + ']';
				})
				.replace(/'?\.'?|\['?/g, ';')
				.replace(/;;;|;;/g, ';..;')
				.replace(/;$|'?\]|'$/g, '')
				.replace(/#([0-9]+)/g, function ($0, $1) {
					return subx[$1];
				});
		},
		asPath: function (path: string) {
			var x = path.split(';'),
				p = '$';
			for (var i = 1, n = x.length; i < n; i++)
				p += /^[0-9*]+$/.test(x[i]) ? '[' + x[i] + ']' : "['" + x[i] + "']";
			return p;
		},
		store: function (p: string, v: any) {
			if (p) P.result[P.result.length] = P.resultType === 'PATH' ? P.asPath(p) : v;
			return !!p;
		},
		trace: function (expr: string, val: string | any[], path: string) {
			if (expr) {
				var x: any = expr.split(';'),
					loc = x.shift();
				x = x.join(';');
				if (val && val.hasOwnProperty(loc)) P.trace(x, val[loc], path + ';' + loc);
				else if (loc === '*')
					P.walk(loc, x, val, path, function (m: string, l: any, x: string, v: any, p: any) {
						P.trace(m + ';' + x, v, p);
					});
				else if (loc === '..') {
					P.trace(x, val, path);
					P.walk(
						loc,
						x,
						val,
						path,
						function (m: string, l: any, x: string, v: { [x: string]: any }, p: string) {
							typeof v[m] === 'object' && P.trace('..;' + x, v[m], p + ';' + m);
						},
					);
				} else if (/,/.test(loc)) {
					// [name1,name2,...]
					for (var s = loc.split(/'?,'?/), i = 0, n = s.length; i < n; i++)
						P.trace(s[i] + ';' + x, val, path);
				} else if (/^\(.*?\)$/.test(loc))
					// [(expr)]
					P.trace(P.eval(loc, val, path.substr(path.lastIndexOf(';') + 1)) + ';' + x, val, path);
				else if (/^\?\(.*?\)$/.test(loc))
					// [?(expr)]
					P.walk(
						loc,
						x,
						val,
						path,
						function (m: string, l: string, x: string, v: { [x: string]: any }, p: any) {
							if (P.eval(l.replace(/^\?\((.*?)\)$/, '$1'), v[m], m)) P.trace(m + ';' + x, v, p);
						},
					);
				else if (/^(-?[0-9]*):(-?[0-9]*):?([0-9]*)$/.test(loc))
					// [start:end:step]  phyton slice syntax
					P.slice(loc, x, val, path);
			} else P.store(path, val);
		},
		walk: function (
			loc: any,
			expr: any,
			val: any,
			path: any,
			f: (arg0: string | number, arg1: any, arg2: any, arg3: any[], arg4: any) => void,
		) {
			if (val instanceof Array) {
				for (var i = 0, n = val.length; i < n; i++) if (i in val) f(i, loc, expr, val, path);
			} else if (typeof val === 'object') {
				for (var m in val) if (val.hasOwnProperty(m)) f(m, loc, expr, val, path);
			}
		},
		slice: function (
			loc: { replace: (arg0: RegExp, arg1: ($0: any, $1: any, $2: any, $3: any) => void) => void },
			expr: string,
			val: string | any[],
			path: any,
		) {
			if (val instanceof Array) {
				var len = val.length,
					start = 0,
					end = len,
					step = 1;
				loc.replace(/^(-?[0-9]*):(-?[0-9]*):?(-?[0-9]*)$/g, function ($0, $1, $2, $3) {
					start = parseInt($1 || start);
					end = parseInt($2 || end);
					step = parseInt($3 || step);
				});
				start = start < 0 ? Math.max(0, start + len) : Math.min(len, start);
				end = end < 0 ? Math.max(0, end + len) : Math.min(len, end);
				for (var i = start; i < end; i += step) P.trace(i + ';' + expr, val, path);
			}
		},
		eval: function (x: string, _v: any, _vname: any) {
			try {
				return $ && _v && eval(x.replace(/@/g, '_v'));
			} catch (e: any) {
				throw new SyntaxError('jsonPath: ' + e.message + ': ' + x.replace(/@/g, '_v').replace(/\^/g, '_a'));
			}
		},
	};

	var $ = obj;
	if (expr && obj && (P.resultType === 'VALUE' || P.resultType === 'PATH')) {
		P.trace(P.normalize(expr).replace(/^\$;/, ''), obj, '$');
		return P.result.length ? P.result : false;
	}
}

export function getPathValues(obj: FAWKES.I_OBJECT, expr: string, filters?: any, key?: any) {
	if (!Pan.isEmpty(filters)) {
		expr = getRecordPathWithFilters(obj, expr, filters, key);
	}
	expr = expr || '';
	if (expr === '$') {
		expr = '$.*';
	}
	if (!obj) {
		return false;
	}
	let data = jsonPath(obj, expr);
	return data;
}

export function getPathValue(obj: FAWKES.I_OBJECT, expr: string, filters?: any, key?: any) {
	let data = getPathValues(obj, expr, filters, key);
	return data ? data[0] : data;
}

export function insertArrayItemInPath(
	obj: FAWKES.I_OBJECT,
	expr: string,
	index: any,
	insertObj: string | any[],
	filters: any,
) {
	let objArray = getPathValue(obj, expr, filters) || [];
	if (!Pan.isEmpty(objArray) && !Pan.isArray(objArray)) {
		// objArray = [objArray];
		objArray = []; //when grid has default string value 'any'
	}
	if (insertObj.length) {
		objArray = objArray.concat(insertObj);
	} else {
		objArray.push(insertObj);
	}
	setPathValue(obj, expr, objArray, filters);
	return obj;
}

export function deleteArrayItemInPath(obj: FAWKES.I_OBJECT, expr: string, key: any, filters: any) {
	let objArray = getPathValue(obj, expr, filters);
	if (objArray && Array.isArray(objArray)) {
		_.remove(objArray, (item) => {
			return item[INJECTED_REC_ID] === key;
		});
		// objArray.splice(index, 1);
	}
	setPathValue(obj, expr, objArray, filters);
	return obj;
}

export function getRecordPathWithFilters(obj: FAWKES.I_OBJECT, path: string, filters?: any, key?: any) {
	if (!Pan.isEmpty(filters) && !Pan.isEmpty(path)) {
		filters.forEach(
			(filter: { find: { [Symbol.replace](string: string, replaceValue: string): string }; replace: any }) => {
				if (filter && filter.find && filter.replace) {
					let replace = filter.replace;
					if (replace.indexOf(CURRENT_ITEM_KEY) && key) {
						replace = replace.replace(CURRENT_ITEM_KEY, key);
					}
					path = path.replace(filter.find, replace);
				}
			},
		);
		let result = getPathValue(obj, path);
		let lastFilter = filters[filters.length - 1];
		//if last filter is of type append
		if (lastFilter && lastFilter.append && Pan.isArray(result)) {
			//append key for the case of multiple type
			let append = lastFilter.append;
			if (append.indexOf(CURRENT_ITEM_KEY) && key) {
				//replace current item key with actual value
				append = append.replace(CURRENT_ITEM_KEY, key);
				path = path + append;
			} else if (append.indexOf(CURRENT_ITEM_KEY) < 0) {
				//curr item key already replaced in append
				path = path + append;
			}
		}
	}
	return path;
}

export function setPathValue(obj: FAWKES.I_OBJECT, expr: string, val: any, filters?: any, key?: any) {
	if (!Pan.isEmpty(filters)) {
		expr = getRecordPathWithFilters(obj, expr, filters, key);
	}
	return setPathValueRecursive(obj, expr, val, filters, key);
}

export function setPathValueRecursive(obj: FAWKES.I_OBJECT, expr: string, val: any, filters: any, key: any) {
	expr = expr.replace(/\?\(\@\./g, '?(@,');
	let exprParts = expr.split('.');
	let path = [],
		stackPaths = '';
	let startStacking = false;
	for (let part of exprParts) {
		part = part.replace(/\?\(\@\,/g, '?(@.');

		if (!startStacking) {
			if (part.indexOf('-') >= 0 || part.indexOf('@name') >= 0) {
				//to avoid lexical error for - and @
				stackPaths += "['" + part + "']";
			} else {
				stackPaths += stackPaths !== '' ? '.' + part : part;
			}

			let p = jp.paths(obj, stackPaths);
			if (p && p.length > 0) {
				path = p[0];
			} else {
				startStacking = true;
			}
		}

		if (startStacking) {
			if (part.indexOf('?(@.') >= 0) {
				//entry or member filter
				if (part.indexOf('entry') > 0) {
					path.push('entry'); //entry
				} else {
					path.push('member'); //member
				}
				path.push(0);
			} else {
				path.push(part);
			}
		}
	}
	if (path && path.length > 0 && path[0] === '$') {
		path.shift();
	}
	if (path.length <= 0) {
		return false;
	}
	let newPath = path.map((item: any) => {
		if (isNaN(item)) {
			return item;
		} else {
			return Number(item);
		}
	});
	let jsonObj = jsonQ(obj);
	if (!val || val === 'null' || (Pan.isObject(val) && val.value && val.value === 'null')) {
		jsonObj.setPathValue(newPath, undefined);
	} else {
		jsonObj.setPathValue(newPath, val);
	}
	return jsonObj;
}

export function deletePath(obj: FAWKES.I_OBJECT, expr: string, filters?: any[], key?: string | undefined) {
	if (Pan.isEmpty(obj)) return false;

	if (!Pan.isEmpty(filters)) {
		expr = getRecordPathWithFilters(obj, expr, filters, key);
	}

	if (expr === undefined) {
		return;
	}

	expr = expr.replace(/\?\(\@\./g, '?(@,'); //to avoid splitting the filter string replace . with ,
	let exprParts = expr.split('.');
	let path = [],
		stackPaths = '';
	let startStacking = false;
	for (let part of exprParts) {
		part = part.replace(/\?\(\@\,/g, '?(@.');

		if (!startStacking) {
			if (part.indexOf('-') >= 0 || part.indexOf('@name') >= 0) {
				//to avoid lexical error for - and @
				stackPaths += "['" + part + "']";
			} else {
				stackPaths += stackPaths !== '' ? '.' + part : part;
			}

			let p = jp.paths(obj, stackPaths);
			if (p && p.length > 0) {
				path = p[0];
			} else {
				startStacking = true;
			}
		}

		if (startStacking) {
			if (part.indexOf('?(@.') >= 0) {
				//entry or member filter
				if (part.indexOf('entry') > 0) {
					path.push('entry'); //entry
				} else {
					path.push('member'); //member
				}
				path.push(0);
			} else {
				path.push(part);
			}
		}
	}
	if (path && path.length > 0 && path[0] === '$') {
		path.shift();
	}
	if (path.length <= 0) {
		return false;
	}
	for (var i = 0; i < path.length - 1; i++) {
		obj = obj[path[i]];
		if (Pan.isEmpty(obj)) return false;
	}
	if (!Pan.isObject(obj)) {
		return false;
	}
	delete obj[path[path.length - 1]];
}

export function mergeJson(deep: FAWKES.I_OBJECT, target: FAWKES.I_OBJECT, src: FAWKES.I_OBJECT) {
	jsonQ(deep, target, src);
}

export function JSONStringify(object: FAWKES.I_OBJECT) {
	var str = JSON.stringify(
		object,
		// custom replacer fxn - gets around "TypeError: Converting circular structure to JSON"
		function (key, value) {
			if (typeof value === 'function' && value !== null) {
				// Circular reference found, it's a function
				return value.toString();
			}
			return value;
		},
		2,
	);
	return str;
}

export function prettyPrint(jsObject: any, indentLength: number | undefined, outputTo: string, fullFunction: any) {
	var indentString: string,
		newLine: string,
		newLineJoin: string | undefined,
		TOSTRING: { (): string; call?: any },
		TYPES: {
			[x: string]: any;
			undefined?: string;
			number?: string;
			boolean?: string;
			string?: string;
			'[object Function]'?: string;
			'[object RegExp]'?: string;
			'[object Array]'?: string;
			'[object Date]'?: string;
			'[object Error]'?: string;
		},
		valueType: { (arg0: any): any; (o: any): any },
		repeatString,
		prettyObject: { (arg0: any, arg1: any): string; (object: any, indent: any): string },
		prettyObjectJSON,
		prettyObjectPrint,
		prettyArray: { (arg0: any, arg1: any): string; (array: any, indent: any): string },
		functionSignature: { (arg0: any): any; (element: any): any },
		pretty: {
			(arg0: any, arg1: string, arg2?: undefined): string;
			(element: any, indent: any, fromArray: any): any;
		},
		visited: any[];

	TOSTRING = Object.prototype.toString;

	TYPES = {
		undefined: 'undefined',
		number: 'number',
		boolean: 'boolean',
		string: 'string',
		'[object Function]': 'function',
		'[object RegExp]': 'regexp',
		'[object Array]': 'array',
		'[object Date]': 'date',
		'[object Error]': 'error',
	};

	valueType = function (o: any) {
		var type = TYPES[typeof o] || TYPES[TOSTRING.call(o)] || (o ? 'object' : 'null');
		return type;
	};

	repeatString = function (src: string, length: number) {
		var dst = '',
			index;
		for (index = 0; index < length; index += 1) {
			dst += src;
		}

		return dst;
	};

	prettyObjectJSON = function (object: { [x: string]: any }, indent: string) {
		var value: string[] = [];

		indent += indentString;
		Object.keys(object).forEach(function (property) {
			value.push(indent + '"' + property + '": ' + pretty(object[property], indent));
		});

		return value.join(newLineJoin) + newLine;
	};

	prettyObjectPrint = function (object: { [x: string]: any }, indent: string) {
		var value: string[] = [];

		indent += indentString;
		Object.keys(object).forEach(function (property) {
			value.push(indent + property + ': ' + pretty(object[property], indent));
		});
		return value.join(newLineJoin) + newLine;
	};

	prettyArray = function (array: string | any[], indent: string) {
		var index,
			length = array.length,
			value = [];

		indent += indentString;
		for (index = 0; index < length; index += 1) {
			value.push(pretty(array[index], indent, indent));
		}

		return value.join(newLineJoin) + newLine;
	};

	functionSignature = function (element: string) {
		var signatureExpression, signature;

		element = element.toString();
		signatureExpression = new RegExp('function\\s*.*\\s*\\(.*\\)');
		signature = signatureExpression.exec(element);
		signature = signature ? signature[0] : '[object Function]';
		return fullFunction ? element : '"' + signature + '"';
	};

	pretty = function (element: { toString: () => string }, indent: string, fromArray?: string) {
		var type;

		type = valueType(element);
		fromArray = fromArray || '';
		if (visited.indexOf(element) === -1) {
			switch (type) {
				case 'array':
					visited.push(element);
					return fromArray + '[' + newLine + prettyArray(element, indent) + indent + ']';

				case 'boolean':
					return fromArray + (element ? 'true' : 'false');

				case 'date':
					return fromArray + '"' + element.toString() + '"';

				case 'number':
					return fromArray + element;

				case 'object':
					visited.push(element);
					return fromArray + '{' + newLine + prettyObject(element, indent) + indent + '}';

				case 'string':
					return fromArray + JSON.stringify(element);

				case 'function':
					return fromArray + functionSignature(element);

				case 'undefined':
					return fromArray + 'undefined';

				case 'null':
					return fromArray + 'null';

				default:
					if (element.toString) {
						return fromArray + '"' + element.toString() + '"';
					}
					return fromArray + '<<<ERROR>>> Cannot get the string value of the element';
			}
		}
		return fromArray + 'circular reference to ' + element.toString();
	};

	if (jsObject) {
		if (indentLength === undefined) {
			indentLength = 2;
		}

		outputTo = (outputTo || 'print').toLowerCase();
		indentString = repeatString(outputTo === 'html' ? '&nbsp;' : ' ', indentLength);
		prettyObject = outputTo === 'print' ? prettyObjectPrint : prettyObjectJSON;
		newLine = outputTo === 'html' ? '<br/>' : '\n';
		newLineJoin = ',' + newLine;
		visited = [];
		return pretty(jsObject, '') + newLine;
	}

	return 'Error: no Javascript object provided';
}

window.jsonPath = jsonPath;
