import { getState } from "@sparky/framework";
import { APIManagerInstance } from "@saseia/ui";

export const fetchMicroAppLicense = async () => {
    const { saseIaGlobalState } = getState();
    const tenantId = saseIaGlobalState?.tenantId;    
    try {
        const ademResponse = await APIManagerInstance.fetchAdemTenantInfo();
        console.debug('SASE IA: ADEM License found for TsgId', tenantId);        
        return ademResponse;
    } catch (err) {
        console.log(
            `SASE IA: ADEM license not found for TsgId: ${tenantId}. Error Message: ${err.errorCode}:${err.details}`
        );
        return { licenseMu: false, licenseRn: false };
    }        
};