import { APIManagerInstance, GlobalStore, isNil, isNilOrEmptyString } from '@saseia/ui';
import { getMFEPropertySubtenant } from './utils/mfeProperty';
import { getAuthState, getMicroAppVars } from "@sparky/framework";

export const fetchAndSetTenantInfo = (props) => {
  const { isPrivateApp, sparkyFramework, auth, providerProps } = props;
  const { initialState } = providerProps;
  const { serverFqdns } = initialState;
  const hasAPIServer = serverFqdns?.apiServer;
  const { setSaseGlobalState } = GlobalStore;
  if (!hasAPIServer) {
    return Promise.reject('Unified App: There is no API server available');
  }
  const featureFlags = getMicroAppVars('pai')?.featureFlags;
  const gcsTsgEnabled = !isNil(featureFlags) ? JSON.parse(featureFlags).gcs_tsg_enabled : false;


  APIManagerInstance.init(providerProps);
  if (gcsTsgEnabled) {
    const setSaseGlobalState = (subtenantList) => {
      const cache = {
        tenantId: getAuthState()?.tsg_id,
        subtenant: subtenantList?.data[0]?.sub_tenant_id,
        subtenantList: subtenantList?.data
      };
      if (isNilOrEmptyString(cache.tenantId) || isNilOrEmptyString(cache.subtenant) || isNilOrEmptyString(cache.subtenantList)) {
        console.error(`Required tenant data is not available or empty string \ntsg: ${cache.tenantId}\nsubtenant: ${cache.subtenant}\nsubtenantList: ${JSON.stringify(cache.subtenantList)}`)
      }
      sparkyFramework.actions.setValues({saseIaGlobalState: cache});
    }

    const tsgId = auth?.tsg_id;
    const sparkyMfeSubtenantInfo = getMFEPropertySubtenant(auth);

    if (!isNilOrEmptyString(sparkyMfeSubtenantInfo)) {
      setSaseGlobalState(sparkyMfeSubtenantInfo)
    } else {
      APIManagerInstance.fetchTenantInfo(tsgId)
          .then((data) => {
            setSaseGlobalState(data[0])
          });
    }
  } else {
    if (isPrivateApp) {
      sparkyFramework.dedupPromise(
          "cosmosTenantWithSubtenantsForAllRegions",
          APIManagerInstance.getTenantWithSubtenantsForAllRegions.bind(APIManagerInstance),
          { keepPromiseIn: -1 }
      )
          .then((data) => {
            setSaseGlobalState(
                {
                  tenantList: data
                }
            );
          });
    } else {
      const setSaseGlobalState = (subtenantList) => {
        const cache = {
          tenantId: getAuthState()?.tsg_id,
          subtenant: subtenantList?.data[0]?.sub_tenant_id,
          subtenantList: subtenantList?.data
        };
        if (isNilOrEmptyString(cache.tenantId) || isNilOrEmptyString(cache.subtenant) || isNilOrEmptyString(cache.subtenantList)) {
          console.error(`Required tenant data is not available or empty string \ntsg: ${cache.tenantId}\nsubtenant: ${cache.subtenant}\nsubtenantList: ${JSON.stringify(cache.subtenantList)}`)
        }
        sparkyFramework.actions.setValues({saseIaGlobalState: cache});
      }

      const tsgId = auth?.tsg_id;
      const sparkyMfeSubtenantInfo = getMFEPropertySubtenant(auth);

      if (!isNilOrEmptyString(sparkyMfeSubtenantInfo)) {
        setSaseGlobalState(sparkyMfeSubtenantInfo)
      } else {
        APIManagerInstance.fetchTenantInfo(tsgId)
            .then((data) => {
              setSaseGlobalState(data[0])
            });
      }
    }
  }
}
